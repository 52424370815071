#portfolio {
  padding: 4rem 0 ;
  text-align: center;
  background-color: var(--color-bg);
}

.portfolio__container {
  padding-top: 3rem;
  display: grid;
  grid-template-columns: repeat(1fr, 1fr);
  gap: 4rem;
  
}

.card {
  border-radius: 0.7rem;
  border: 1px solid var(--color-bg-variant);
  display: grid;
  grid-template-columns: 2fr 3fr;
  padding: 1rem;
  gap: 1rem;
  align-items: center;
}

.card__image img{
  border-radius: 0.7rem;
  filter: grayscale(100%);
  transition: var(--transition);
}

.card__image img:hover {
  filter: none;
}

.card__body h4 {
  margin-top: 0.5rem;
}

.card__button {
  padding: 0.5rem 0;
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.card__front-end,
.card__back-end {
  padding: 0.3rem 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: .3rem;
  margin-bottom: 0.5rem;
}


.label {
  background-color: rgba(61, 61, 61, 0.7);
  padding: 0.2rem 0.5rem;
  border-radius: 0.2rem;
}

/* ------------------ Media Queries (Medium devices) */
@media screen and (max-width: 1024px) {
  .portfolio__container {
    grid-template-columns: 1fr;
    gap: 1.2rem;
  }

  .card {
    grid-template-columns: 1fr;
  }

  .card__image {
    width: 80%;
    margin: 0 auto;
  }
}

/* ------------------ Media Queries ( Small devices) */
@media screen and (max-width: 600px) {
  .portfolio__container {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
}