.nav {
  position: fixed;
  left: 3%;
  top: 0;
  border-radius: 1.7rem;
  z-index: 10;
}

.nav-list:first-child:before {
  content: '';
  display: block;
  width: 1px;
  height: 100px;
  margin: 0 auto;
  background-color: var(--color-white);
}

.nav-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.nav-list li {
  height: 40px;
}

.nav-list__item {
  padding: 0.7rem;
  border-radius: 50%;
  border: 1px solid transparent;
  box-sizing: content-box;
  color: var(--color-white);
  transition: var(--transition);
}

.nav-list__item:hover {
  background-color: rgba(255,255,255, 0.7);
  color: var(--color-bg);
  
}

/* ------------------ Media Queries (Medium devices) */
@media screen and (max-width: 1024px) {
  .nav {
    left: 1.5%;
  }
}


/* ------------------ Media Queries (Small devices) */
@media screen and (max-width: 600px) {
  .nav {
    display: none;
  }
}