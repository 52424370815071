@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&family=Roboto+Mono:wght@300;400&family=Roboto:wght@300;400;500;700&display=swap');

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
}

/* VARIABLES */
:root {
   --color-bg: #1f1f38;
  --color-bg-dark: #12122bec;
  --color-bg-trans: rgba(31, 31, 56, 0.678);
  --color-bg-variant: #2c2c6c;
  --color-bg-overlay: rgba(0, 0, 0, 0.4);
  --color-primary: #4db5ff;
  --color-primary-light: #4db5ff62;
  --color-primary-variant: rgba(77, 181, 255, 0.4);
  --color-white: rgba(245, 246, 250,1.0);
  --color-light: rgba(220, 221, 225,0.8);

  /* EXTRA COLORS */
  --color-gray: rgba(127, 143, 166,1.0);
  --color-gray-variant: rgba(113, 128, 147,1.0);
  --color-light-blue: #00b4d8;
  --color-light-blue-variant: #00a7fbbb;

  /* FONTS  */
  --font-poppins: 'Poppins', sans-serif;
  --font-roboto: 'Roboto', sans-serif;
  --font-roboto-mono: 'Roboto Mono', monospace;


  --transition: all 400ms ease;

  --container-width-lg: 75%;
  --container-width-md: 86%;
  --container-width-sm: 90%;
}

/* ----------- BASIC STYLES  -----------------*/
body {
  font-family: var(--font-roboto-mono);
  color: var(--color-white);
  line-height: 1.7;
}

body:before {
  content: "";
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -10;
  background: url(./assets/shutterstock_680664079.png) no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.font-poppins {
  font-family: var(--font-poppins);
}

html {
  scroll-behavior: smooth;
  /* background-image: url(./assets/bg1.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed; */
}

.hamburger-button {
  display: none;
}

::-webkit-scrollbar {
  display: none;
}

/* ------------- General Styles --------------- */
.container {
  width: var(--container-width-lg);
  margin: 0 auto;
}

h1, h2, h3, h4, h5 {
  font-weight: 500;
}

h1 {
  font-size: 2.5rem;
}

a {
  color: var(--color-white);
  transition: var(--transition);
}

a:hover {
  color: var(--color-primary);
}

img {
  display: block;
  width: 100%;
  object-fit: cover;
}

.icon {
  margin-bottom: -2px;
}

.btn {
  width: max-content;
  display: inline-block;
  color: var(--color-primary);
  padding: 0.4rem 1rem;
  border-radius: 0.4rem;
  cursor: pointer;
  border: 1px solid var(--color-primary);
  font-family: var(--font-roboto-mono);
  transition: var(--transition);
}

.btn-primary {
  background-color: var(--color-primary);
  color: var(--color-bg);
  transition: var(--transition);
}

.btn-primary:hover {
  color: var(--color-primary);
  border: 1px solid var(--color-primary);
  background-color: var(--color-bg);
}

.btn-outline-primary {
  border: 1px solid var(--color-primary);
  transition: var(--transition);
}

.btn-outline-primary:hover {
  background-color: var(--color-primary);
  color: var(--color-bg);
}

/* ------------------ Media Queries (Medium devices) */
@media screen and (max-width: 1024px) {
  .container {
    width: var(--container-width-md);
  }

}

/* ------------------ Media Queries (Small devices) */
@media screen and (max-width: 600px) {
  .container {
    width: var(--container-width-sm);
  }

  section > h2 { 
    margin-bottom:  2rem;
  }

  .hamburger-button {
    display: block;
    position: fixed;
    top: 1.5rem;
    right: 1.5rem;
    z-index: 8;
  }
}