#about {
  background-color: var(--color-bg-overlay);
}

.about__container {
  padding: 4rem 0 6rem 0;
}

.about__container h2 {
  padding-bottom: 3rem;
}

.about__content {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 1.5rem;
}

.about__content-left {
  text-align: center;
  padding: 0 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--color-bg-trans);
  border-radius: 2rem;
  border: 1px solid var(--color-bg-variant);
}

.about__content-left p {
  padding-bottom: 1rem;
}

.cv-button {
  padding-top: 1.5rem;
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.about__content-right {
  border: 1px solid var(--color-bg-variant);
  /* border-top-left-radius: 15rem;
  border-top-right-radius: 15rem; */
  border-radius: 2rem;
  background-color: var(--color-bg-trans);
  transition: var(--transition);
  width: fit-content;
}

.about__content-right:hover {
  transform: rotateZ(-15deg) scale(0.9);
}

.about__content-right img {
  max-height: 450px;
  width: auto;
  object-fit: fill;
  opacity: 0.8;
  border-radius: 2rem;
}

/* ------------------ Media Queries (Medium devices) */
@media screen and (max-width: 1400px) {
  .container {
    width: var(--container-width-md);
  }
}

@media screen and (max-width: 1024px) {
  .container {
    width: var(--container-width-md);
  }

  .about__content {
    grid-template-columns: 1fr;

  }

  .about__content-left {
    padding: 2rem 3rem;
    border-radius: 3rem;
  }

  .about__content-right{
    transform: translateX(50%);
  }
}

/* ------------------ Media Queries (Small devices) */
@media screen and (max-width: 600px) {
  .container {
    width: var(--container-width-sm);
  }

  .about__content {
    gap: 3.5rem;
  }

  .cv-button {
    flex-direction: column;
    align-items: center;
  }
  .about__content-left {
    order: 2;
  }
  .about__content-right{
    transform: none;
    margin: 0 auto;
    order: 1;
  }

  .about__content-right img {
    max-height: 300px;
  }

  .about__content-right:hover {
    transform: none;
  }
}