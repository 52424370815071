#contact {
  background-color: var(--color-bg-overlay);
  padding-bottom: 3rem;
}

.contact__container {
  padding-top: 4rem;
}

.contact__container h2 {
  margin-bottom: 3rem;
}

.contact__content {
  width: 75%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;
  margin-bottom: 3rem;
}

.form-label {
  margin-bottom: 1rem;
}

.form-control {
  margin-bottom: 1.5rem;
}

.form-item {
  padding: 0.75rem 0.5rem;
  display: block;
  width: 100%;
  background-color: var(--color-bg-trans);
  border: 1px solid var(--color-bg-variant);
  border-radius: 5px;
  color: var(--color-white);
  resize: none;
}

.form-item:focus {
  border-color: var(--color-primary);
}

.form-item::placeholder {
  color: var(--color-white);
  font-family: var(--font-roboto-mono);
}

.submit {
  display: flex;
  justify-content: center;
}

.submit button {
  font-size: 1rem;
  padding: .6rem 1rem;
}

.contact__info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  border: 1px solid var(--color-bg-variant);
  border-radius: 0.5rem;
  padding: 1rem 1.5rem;
  background-color: var(--color-bg-trans);
}

.contact__info p {
  padding: 1rem 0;
}

.contact__info ul li::before {
  content: '- ';
}

.form-message-success {
  text-align: center;
  border: 1px solid;
  border-radius: 0.5rem;
  width: 50%;
  margin: 0 auto 2rem auto;
  padding: 0.5rem 0;
  background-color: var(--color-bg-trans);
  color: #06d506;
}

.form-message-error {
  text-align: center;
  border: 1px solid;
  border-radius: 0.5rem;
  width: 50%;
  margin: 0 auto 2rem auto;
  padding: 0.5rem 0;
  background-color: var(--color-bg-trans);
  color: #d50606;
}

/* ------------------ Media Queries (Medium devices) */
@media screen and (max-width: 1024px) {
  .container {
    width: var(--container-width-md);
  }

  .contact__content {
    width: 100%;
  }

  .form-message-success, 
  .form-message-error {
    width: 75%;
  }
}

/* ------------------ Media Queries (Small devices) */
@media screen and (max-width: 600px) {
  .container {
    width: var(--container-width-sm);
  }

  .contact__content {
    grid-template-columns: 1fr;
  }

  .contact__form {
    order: 2;
  }
}