header {
  height: 100vh;
  position: relative;
  z-index: 1;  
}

.header__container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.header__row-1,
.header__row-2,
.header__row-3 {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  
}

.header__first-row-big,
.header__name-big,
.header__title-big {
  font-size: 2rem;
  transition: all 350ms ease-in-out;
}

.header__name-big {
  font-size: 4rem;
}

.header__title-big {
  font-size: 2.6rem;
}

.name {
  font-size: 2.6rem;
}

/* HOVER EFFECTS */
.header__name-big:hover {
  transform: rotateZ(12deg);
}

.header__title-big:hover {
  transform: rotateZ(-11deg);
}

.header__first-row-big:hover {
  transform: rotateZ(-10deg);
}

.overlay {
  content: '';
  background-color: var(--color-bg-overlay);
  position: absolute; 
  height: 100%;
  width: 100%;
  z-index: -1;
}

/* --- SOCIAL LINKS STYLES --- */
.social-links__wrap {
  position: fixed;
  width: 40px;
  left: 40px;
  bottom: 0;
  right: auto;
  z-index: 10;

}
.social-links {
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.social-links li {
  padding-bottom: 1rem;
}

.social-links:last-child::after {
  content: '';
  display: block;
  width: 1px;
  height: 100px;
  margin: 0 auto;
  background-color: var(--color-white);
}

.social-email__wrap {
  width: 40px;
  position: fixed;
  bottom: 0px;
  left: auto;
  right: 40px;
  z-index: 10;
}

/* --- SOCIAL EMAIL STYLES --- */
.social-email {
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
}

.social-email a {
  display: inline-block;
  margin: 20px auto;
  padding: 10px;
  font-family: var(--font-roboto-mono);
  font-size: 0.8rem;
  letter-spacing: 0.1em;
  writing-mode: vertical-rl;
}


.social-email::after {
  content: '';
  display: block;
  width: 1px;
  height: 50px;
  margin: 0 auto;
  background-color: var(--color-white);
}

/* ------------------ Media Queries (Medium devices) */
@media screen and (max-width: 1024px) {
  .container {
    width: var(--container-width-md);
  }

  .social-email,
  .social-links {
    display: none;
  }
}

/* ------------------ Media Queries (Small devices) */
@media screen and (max-width: 600px) {
  .container {
    width: var(--container-width-sm);
  }

  .header__row-2,
  .header__row-3 {
    flex-direction: column;
    gap: 0;
  }

  .header__row-3 {
    padding-top: 2rem;
  }
  
}